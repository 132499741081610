
.@{class-prefix}-bill-viewer-reduction{
    padding: 8px 16px !important;
    &.ant-descriptions-item-label{
        color: @danger-color;
    }
    &.ant-descriptions-item-content{
        background-color: lighten(@danger-color, 20%);
        color: @white-color;    
    }    
}