@import (css) url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");


.form-login-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.frontend-container {
   font-family: "Lato", sans-serif;
}

.frontend-container {
   text-align: center;

   .gx-bg-secondary, .gx-bg-secondary:hover {
      background-color: @secondary-color !important;
   }

   .bold {
      font-weight: 500;
   }

   .btn-f,
   .btn-f:hover {
      font-size : 21px;
      border    : 0;
      padding   : 10px 24px !important;
      height    : auto;
      min-height: auto;
   }

   .btn-f-lg,
   .btn-f-lg:hover {
      font-size : 32px;
      border    : 0;
      padding   : 18px 50px !important;
      height    : auto;
      min-height: auto;
   }

   .h1-title {
      padding-top: 100px;
   }

   .pt-50 {
      padding-top: 50px;
   }

   .mb-50 {
      margin-bottom: 50px;
   }

   .mb-100 {
      margin-bottom: 100px;
   }

   .margin-request-info {
      margin-top   : 130px;
      margin-bottom: 175px;
   }

   h1 {
      font-size  : 40px;
      font-weight: 600;
   }

   h2 {
      font-size  : 26px;
      font-weight: normal;
   }

   h3 {
      font-size  : 30px;
      font-weight: bold;
   }

   .blue {
      color: @primary-color;
   }

   .orange {
      color: @secondary-color;
   }

   .card,
   .card-blue {
      background-color: #f39b1a26;
      padding         : 24px;
      width           : 100%;
      margin-bottom   : 40px;
      font-size       : 16px;

      .linea {
         width           : 30%;
         height          : 2px;
         background-color: @secondary-color;
         margin          : 0 auto;
         margin-top      : 25px;
         margin-bottom   : 25px;
      }
   }

}

.mh-90vh {
   min-height: 90vh;
}

.mh-100vh {
   min-height: 100vh;
}

#login-form {
   background-color     : #ffffff;
   padding              : 30px;
   -webkit-border-radius: 6px;
   -moz-border-radius   : 6px;
   border-radius        : 6px;

   input[type="text"],
   input[type="password"] {
      font-size     : 18px;
      padding-top   : 10px;
      padding-bottom: 10px;
   }

  -webkit-box-shadow: 0px 0px 10px 5px rgba(103,103,103,0.4);
  box-shadow: 0px 0px 10px 5px rgba(103,103,103,0.4);
}

.gx-app-login-wrap2 {
  margin:0 auto !important;
  max-width:500px !important;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(103,103,103,0.4);
  box-shadow: 0px 0px 10px 5px rgba(103,103,103,0.4);

  -webkit-border-radius: 6px;
   -moz-border-radius   : 6px;
   border-radius        : 6px;
}
